<script setup></script>

<template>
  <div class="flex">
    <main class="flex-1 mt-4">
      <slot />
    </main>
  </div>
</template>

<style scoped>
*,
html {
  font-family: "Open Sans", sans-serif;
}
</style>
